.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@layer base {
    @font-face {
        font-family: "Poppins";
        src: url("../src/fonts/poppins/Poppins-Regular.ttf");
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.td-table-cell {
    padding: 12px !important;
    font-size: 14px !important;
}

.rdrMonthAndYearWrapperCustom {
    visibility: hidden;
}

.rdrDateRangeWrapperCustom {
    width: 300px !important;
}

.rdrDefinedRangesWrapper {
    width: 140px !important;
}

@media (max-width: 560px) {
    .rdrDateRangePickerWrapper {
        display: block !important;
    }

    .rdrDefinedRangesWrapper {
        width: auto !important;

    }

    .rdrMonthsVertical, .rdrMonth {
        width: 98% !important;
    }

    .rdrDateRangeWrapper {
        width: 100% !important;
    }

    .rdrStaticRanges {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        flex-direction: row !important;
        flex-wrap: wrap;

    }
}


.ed-user-session-label {
    position: relative;
    left: 10px;
}